import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PageShell from "./modules/common/layout/PageShell";
import Start from "./modules/start/page";
import Success from "./modules/success/page";
import Failure from "./modules/failure/page";
import Tutorial from "./modules/tutorial/page";
import Open from "./modules/open/page";
import TouchSensitivity from "./modules/touchSensitivity/page";
import ScreenSize from "./modules/screenSize/page";
import Swipe from "./modules/swipe/page";
import Notice from "./modules/notice/page";
import FAQ from "./modules/faq/page";

// pages

type RouterComponentSpecificProps = {
  history: any;
  location: any;
  match: any;
};

class RouterComponent extends React.Component<RouterComponentSpecificProps> {
  currentPathname: null;
  currentSearch: null;

  constructor(props: RouterComponentSpecificProps) {
    super(props);

    // Store the previous pathname and search strings
    this.currentPathname = null;
    this.currentSearch = null;
  }

  componentDidMount() {
    const { history } = this.props;

    history.listen(
      (newLocation: { pathname: null; search: null }, action: string) => {
        if (action === "PUSH") {
          if (
            newLocation.pathname !== this.currentPathname ||
            newLocation.search !== this.currentSearch
          ) {
            // Save new location
            this.currentPathname = newLocation.pathname;
            this.currentSearch = newLocation.search;

            // Clone location object and push it to history
            history.push({
              pathname: newLocation.pathname,
              search: newLocation.search,
            });
          }
        } else {
          // Send user back if they try to navigate back
          console.log(newLocation, action);
          if (newLocation.pathname === "/success") {
            // coming back from pdf -> allow backButton
          } else if (newLocation.pathname === "/failure") {
            // coming back from external link -> allow backButton
          } else {
            history.go(1);
          }
        }
      }
    );
  }

  getTransitions = (path: string) => {
    let transitionClass = "";

    switch (path) {
      case "/welcome":
        transitionClass = "fadeIn";
        break;
      case "/select":
        transitionClass = "fadeIn";
        break;
      case "/touch":
        transitionClass = "fadeIn";
        break;
      case "/swipe":
        transitionClass = "slideFromBottom";
        break;
      default:
        transitionClass = "slideFromRight";
        break;
    }
    return transitionClass;
  };

  render() {
    return (
      <Route
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition
              key={location.pathname}
              classNames={this.getTransitions(location.pathname)}
              timeout={600}
            >
              <Switch location={location}>
                <Route exact path="/" component={PageShell(Open)} />
                <Route path="/screenSize" component={PageShell(ScreenSize)} />
                <Route path="/start" component={PageShell(Start)} />
                <Route path="/tutorial" component={PageShell(Tutorial)} />
                <Route
                  path="/touchSensitivity"
                  component={PageShell(TouchSensitivity)}
                />
                <Route path="/swipe" component={PageShell(Swipe)} />
                <Route path="/faq" component={PageShell(FAQ)} />
                <Route path="/success" component={PageShell(Success)} />
                <Route path="/failure" component={PageShell(Failure)} />
                <Route path="/notice" component={PageShell(Notice)} />
                <Route component={Notice} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
    );
  }
}

export default withRouter(RouterComponent);
