import Lottie from "react-lottie-player";
import anime from "animejs";
import { useRouter } from "../../hooks/useRouter";
import { useTranslation } from "react-i18next";
import { useInvocationCheck } from "../../hooks/useInvocationCheck";
import { useDataReset } from "../../hooks/useDataReset";
import OrientationLock from "../common/layout/screenOrientation/OrientationLock";
import MessageTitle from "../common/components/MessageTitle";
import IconButton from "../common/ui/IconButton";
import Button from "../common/ui/Button";
import Sound from "../common/sound/Sound";

import click from "../../assets/sounds/click.mp3";
import bg from "../../assets/img/bg/bg.jpg";
import verified from "./assets/document-verified.json";
import pdf from "./assets/prismade_security.pdf";
import { useEffect } from "react";

type Props = {};

function Verified(props: Props) {
  const router = useRouter();
  const { t } = useTranslation();

  const clickSound = new Sound(click);

  useInvocationCheck();
  useDataReset();

  useEffect(() => {
    setTimeout(() => {
      anime.set(["#cardName"], { scale: 0.5 });

      anime({
        targets: ["#cardName"],
        opacity: 1.0,
        scale: 1.3,
        duration: 1000,
        easing: "easeInQuad",
        complete: function () {
          anime({
            targets: "#cardName",
            scale: 1.0,
            duration: 500,
            easing: "easeInQuad",
          });
        },
      });
    }, 500);
  }, []);

  return (
    <OrientationLock>
      <div
        className="absolute top-0 flex flex-col items-center justify-between w-screen h-screen p-8 text-center bg-center bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="relative flex items-center justify-center w-screen text-center">
          <div className="absolute left-0">
            <IconButton
              icon="arrow-left"
              onClick={() => {
                clickSound.play();
                router.push("/start");
              }}
            />
          </div>
          <MessageTitle>{t("success:title")}</MessageTitle>
        </div>
        <div>
          <Lottie
            animationData={verified}
            play
            style={{ width: 200, height: 200 }}
            loop={1}
          />
          <p
            className="mt-2 text-xl font-bold text-prismade-blue"
            id="cardName"
          >
            {t("success:cardName")}
          </p>
        </div>
        <div className="w-full mt-4">
          <Button
            onClick={() => {
              window.open(pdf);
            }}
          >
            {t("success:button")}
          </Button>
        </div>
      </div>
    </OrientationLock>
  );
}

export default Verified;
