import anime from "animejs";
import { useTranslation } from "react-i18next";
import { useRouter } from "../../hooks/useRouter";
import { useEffect } from "react";
import Button from "../common/ui/Button";
import Sound from "../common/sound/Sound";
import click from "../../assets/sounds/click.mp3";
import prismade from "../../assets/img/logos/prismade.svg";

import bg from "../../assets/img/bg/bg.jpg";
import card from "./img/card-front.png";
import frame from "./img/frame-green.png";
import label from "./img/label-blanko.png";
import check from "./img/shield-fill-check.png";

// Please, only visuals here, as this component is also used as background.
// Checks and Google Analytics calls are to be set in /start/page.tsx

function StartComponent() {
  const router = useRouter();
  const [t] = useTranslation();
  const clickSound = new Sound(click);

  useEffect(() => {
    // card, frame, label, check
    anime.set(["#label"], {
      translateY: -130,
    });
    anime.set(["#frame"], {
      opacity: 0,
    });
    anime.set(["#check"], {
      scale: 0,
      opacity: 0,
    });
    setTimeout(() => {
      anime({
        targets: ["#label"],
        translateY: 0,
        duration: 1200,
        easing: "easeInOutQuad",
        complete: () => {
          anime({
            targets: ["#frame"],
            opacity: 1,
            duration: 700,
            easing: "linear",
          });
          anime({
            targets: ["#check"],
            opacity: 1,
            scale: 1,
            duration: 1000,
            easing: "easeInOutQuad",
          });
        },
      });
    }, 1500);
  }, []);

  return (
    <div
      className="flex flex-col items-center justify-between w-screen min-h-screen pt-8 text-center whitespace-pre-wrap bg-center bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="z-10 flex flex-col w-full px-8 text-center">
        <img src={prismade} alt="Logo Prismade" className="h-12 mb-8" />
        <p className="px-8 text-lg font-bold">{t("start:title")}</p>
      </div>

      <div className="z-10 w-full px-6 pb-2">
        <Button
          onClick={() => {
            clickSound.play();
            router.push("/screenSize");
          }}
        >
          {t("start:button")}
        </Button>
        <a
          href="https://prismade.com/contact/"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center justify-center w-full px-3 py-3 mt-2 text-lg font-medium text-center rounded-md text-prismade-blue"
        >
          {t("start:button2")}
        </a>
      </div>
      <div className="absolute top-0 z-0 w-screen h-screen overflow-hidden">
        <div
          className="relative"
          style={{
            width: "140%",
            left: "50%",
            top: "15%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img src={card} alt="card" id="card" className="absolute" />
          <img src={frame} alt="frame" id="frame" className="absolute" />
          <img src={label} alt="label" id="label" className="absolute" />
          <img src={check} alt="check" id="check" className="absolute" />
        </div>
      </div>
    </div>
  );
}

export default StartComponent;
