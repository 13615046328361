import Lottie from "react-lottie-player";
import { useTranslation } from "react-i18next";
import { useRouter } from "../../hooks/useRouter";
import { useInvocationCheck } from "../../hooks/useInvocationCheck";
import { useDataReset } from "../../hooks/useDataReset";
import OrientationLock from "../common/layout/screenOrientation/OrientationLock";
import MessageTitle from "../common/components/MessageTitle";
import Button from "../common/ui/Button";
import IconButton from "../common/ui/IconButton";
import MessageParagraph from "../common/components/MessageParagraph";
import Sound from "../common/sound/Sound";

import click from "../../assets/sounds/click.mp3";
import bg from "../../assets/img/bg/bg.jpg";
import not_verified from "./assets/document-not-verified.json";

type Props = {};

function Failure(props: Props) {
  const router = useRouter();
  const { t } = useTranslation();

  const clickSound = new Sound(click);

  useInvocationCheck();
  useDataReset();

  return (
    <OrientationLock>
      <div
        className="absolute top-0 flex flex-col items-center justify-between w-screen h-screen p-8 bg-center bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="relative flex items-center justify-center w-screen text-center">
          <div className="absolute left-0">
            <IconButton
              icon="arrow-left"
              onClick={() => {
                clickSound.play();
                router.push("/start");
              }}
            />
          </div>
          <MessageTitle>{t("failure:title")}</MessageTitle>
        </div>
        <MessageParagraph>{t("failure:p1")}</MessageParagraph>
        <div>
          <Lottie
            animationData={not_verified}
            play
            style={{ width: 200, height: 200 }}
            loop={1}
          />
        </div>
        <div className="w-full space-y-4">
          <Button
            onClick={() => {
              clickSound.play();
              router.push("/start");
            }}
          >
            {t("failure:button1")}
          </Button>
          <a
            href="https://www.prismade.com"
            className="inline-flex items-center justify-center w-full px-3 py-3 text-lg font-medium text-center bg-white rounded-md text-prismade-blue"
          >
            {t("failure:button2")}
          </a>
        </div>
      </div>
    </OrientationLock>
  );
}

export default Failure;
