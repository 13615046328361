import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import RouterComponent from "./RouterComponent";
import "./i18n";
import { HelmetProvider } from "react-helmet-async";
import { OrientationChangeProvider } from "./modules/common/layout/screenOrientation/OrientationChangeProvider";

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <OrientationChangeProvider>
        <Router>
          <RouterComponent />
        </Router>
      </OrientationChangeProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
